exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-behandelingen-js": () => import("./../../../src/pages/behandelingen.js" /* webpackChunkName: "component---src-pages-behandelingen-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mondhygieniste-js": () => import("./../../../src/pages/mondhygieniste.js" /* webpackChunkName: "component---src-pages-mondhygieniste-js" */),
  "component---src-pages-praktijk-js": () => import("./../../../src/pages/praktijk.js" /* webpackChunkName: "component---src-pages-praktijk-js" */),
  "component---src-pages-veelgestelde-vragen-js": () => import("./../../../src/pages/veelgestelde-vragen.js" /* webpackChunkName: "component---src-pages-veelgestelde-vragen-js" */)
}

